<script lang="ts" setup>
import { useStore } from '@nanostores/vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { cartWithDiscounts as cartWithDiscountsStore } from '../services/cart'
import CartDialog from './CartDialog.vue'
import Price from './Price.vue'

const cartWithDiscounts = useStore(cartWithDiscountsStore)

const open = ref(false)

const handleHashChange = (event) => {
  const url = new URL(event.newURL)
  const hash = url.hash
  if (hash === '#cart') {
    open.value = true
  } else {
    open.value = false
  }
}

onMounted(() => {
  addEventListener('hashchange', handleHashChange)

  if (window.location?.hash === '#cart') {
    open.value = true
  }
})

onBeforeUnmount(() => {
  removeEventListener('hashchange', handleHashChange)
})

const onClose = () => {
  const url = new URL(window.location.href)
  url.hash = ''
  window.history.pushState('', '', url)
  open.value = false
}
</script>

<template>
  <a
    href="#cart"
    class="p-3 w-36 h-full flex items-center justify-between text-white rounded-tl-3xl bg-purple-700 hover:bg-purple-600 focus:ring-purple-400 disabled:bg-purple-200 relative border border-transparent shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
  >
    <span class="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
        />
      </svg>
      <span
        class="absolute left-4 -top-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-bold bg-red-500 text-white"
        >{{ cartWithDiscounts?.size }}</span
      >
    </span>
    <Price :value="cartWithDiscounts?.productsTotal" size="lg" />
    <span class="sr-only">Carrinho</span>

    <CartDialog :open="open" @closeCart="onClose" />
  </a>
</template>
